.analitica {
    background-color: rgba(40, 110, 216, 0.493) !important;
}

/* .totaisFichaDeRecebimento{
    background-color: rgba(40, 110, 216, 0.493) !important;
} */

.totais{
    /* background-color: rgba(40, 110, 216, 0.493) !important; */
    background-color: rgba(132, 178, 246, 0.493) !important;
}

.pdvRelatorioFechamentoCaixa{
    /* background-color: rgba(40, 110, 216, 0.493) !important; */
    background-color: rgba(132, 250, 193, 0.493) !important;
}

.diferencaRelatorioFechamentoCaixa{
    /* background-color: rgba(40, 110, 216, 0.493) !important; */
    background-color: rgba(222, 111, 115, 0.849) !important;
}

.nfManual{
    /* background-color: rgba(40, 110, 216, 0.493) !important; */
    background-color: rgba(187, 180, 181, 0.849) !important;
}

.ajusteFichaDeEstoqueSobra{
    background-color: rgba(40, 110, 216, 0.493) !important;
}

.ajusteFichaDeEstoquePerda{
    background-color: rgba(240, 34, 16, 0.493) !important;
}

.totaisLabel{
    font-weight: 700;
    /* font-size: 13px; */
    color: #286ed8;
}

.superavit{
    background-color: rgba(46, 216, 40, 0.84) !important;
}

/* .p-datatable-scrollable-body {
    padding-bottom: 5px !important;
} */

.linkValue{
    font-weight: 700;
    /* font-size: 13px; */
    color: #5b57df;
}


.linkValue:hover {
    color: rgb(231, 59, 12);
}

.superavitValue{
    font-weight: 700;
    /* font-size: 13px; */
    color: #1cc71c;
}

.deficit{
    background-color: rgba(216, 75, 40, 0.84) !important;
}

.my-menuitem-text {
    color: #c9c0c0 !important; 
}

.my-menuitem-icon {
    color: #c9c0c0 !important;
    padding: 0.2rem;
}

.deficitValue{
    font-weight: 700;
    color: #FF5252;
}


.highlight-search-text {
    background-color: rgb(232, 255, 83) !important;
}

  
.p-datatable.hidden-tfoot tfoot {
    display: none;
}


