.left-align {
    text-align: left;
}

.right-align {
    text-align: right;
}


.small-font {
    font-size: 11px !important;
    font-weight: 650 !important;
}

.small-table-font {
    font-size: 11px !important;
    font-weight: 650 !important;
}

.medium-font {
    font-size: 15px !important
}

.small-font-size {
    font-size: 12px;
    font-weight: 600;
    height: 10px;
}

.medium-font-size {
    font-size: 16px;
    font-weight: 600;
    height: 10px;
}

.input-number-small {
    width: 80px !important;  
}

.expand-button-table {
    color: #ffffff !important;
    background: #a4b2b9 !important;
    border: 1px solid #ffffff !important;
    transition: background-color 1s ease !important;
    width: '3rem' !important;
}

.expand-button-table-transparent {
    color: #ffffff !important;
    background: #f4fafe !important;
    border: 1px solid #f4fafe !important;
    transition: background-color 1s ease !important;
    width: '3rem' !important;
}

.expand-button-table:hover {
    background: #607D8B !important;
}

button.expand-button-table.p-button-icon-only {
    width: 1.8rem !important;
    height: 1.8rem !important;
    padding: 0.5rem !important;
}



/*button:not(.p-button.p-component.p-datepicker-trigger.p-button-icon-only) {*/
button {    
    max-height: 30px;
    font-size: 14px !important;
}

button.small-button {    
    max-height: 20px;
    font-size: 12px !important;
}

button.small-button .p-button-icon {
    font-size: 12px;
    width: 12px;
    height: 12px;
    line-height: 12px
}


button.p-button-rounded:not(.custom-choose-btn):not(.custom-upload-btn):not(.custom-cancel-btn) { /* Exlcui os botões do FileUploadCard */
// button.p-button-rounded{
    max-height: 30px;
    max-width: 30px;
}

button.right-button{
    margin-left: 8px;
}

span.p-menuitem-icon.pi { /* Altera estilo de todas as tags span que contêm as classes .p-menuitem-icon e pi, ou seja, basicamente os icons do primereact  */
    font-size: 0.8rem; 
}

span.p-menuitem-text { /* Altera estilo de todas as tags span que contêm as classes .p-menuitem-icon e pi, ou seja, basicamente os icons do primereact  */
    font-size: 0.8rem; 
}

input, select {
    height: 30px !important;
    font-size: 13px !important; 
    color: #000 !important;
}

span.p-dropdown-label { /* Atera os parâmetros do dropdown do primereact */
    height: 30px !important; 
    font-size: 13px !important; 
    line-height: 13px; //Para alinhar texto veriticalmente
    color: #000 !important;
}

li.p-dropdown-item { /* Altera tamanho da fonte de do texto de dentro dos itens do dropdown do primereact  */
    font-size: 13px; 
}

label {
    font-size: 13px !important; 
}

textarea.p-inputtextarea {
    font-size: 13px !important;
}

.p-multiselect {
    height: 30px;
    font-size: 13px !important;
}

.p-multiselect .p-multiselect-label {
    line-height: 13px; //Para alinhar texto veriticalmente
}

.visaorj-small {
    .p-multiselect-label {
        font-size: 11px !important;
        line-height: 0px !important;
    }
}

.visaorj-small {
    .p-multiselect-trigger {
        height: 15px !important;
    }
}

.visaorj-small {
    .p-multiselect {
        height: 15px !important;
    }
}

.visaorj-small {
    .p-datatable-tbody {
        height: 15px !important;
    }
}

.visaorj-small {
    .p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
        padding: 0rem 0.5rem !important;
    }
}

.visaorj-small {
    .p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
        padding: 0rem 0.5rem !important;
    }
}

.visaorj-small {
    .card-header {
        padding: 0.2rem 1.25rem;
    }
}

.p-inputswitch .p-inputswitch-slider:before {
    width: 0.9rem !important;
    height: 0.9rem !important;
    margin-top: -0.425rem !important;
}

span.p-inputswitch-slider {
    width: 2.5rem !important; 
    height: 1.5rem !important; 
}

h6 {
    font-size: 13px;
}

.pwd {
	position: relative;
}

.p-viewer {
	position: absolute;
	top: 20%;
	right: 10px;
}



input.MuiInputBase-input {
    height: 1rem !important;
}

.centraliza-conteudo-div {
    display: flex;
    justify-content: center; /* Centraliza horizontalmente */
    align-items: center; /* Centraliza verticalmente */
}

.alinha-direita-div {
    float: right;
    margin-right: 10px;
}

.custom-tooltip {
    max-width: 90vw !important; 
}

.header-small-height {
    height: 1.5rem;
}

.borda {
    border: 0.1rem solid black;
    // box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.2);
}

.block-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(54, 52, 52, 0.5); /* Fundo escuro translúcido */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Certifica-se de que o overlay fique acima de todo o conteúdo */
    pointer-events: none; /* Para evitar interação com elementos abaixo */
}

.block-content {
    text-align: center;
    color: white;
    z-index: 10000;
    pointer-events: auto; /* Permite a interação com o conteúdo do spinner */
}

.p-progress-spinner-circle {
    stroke: white!important;
    animation-duration: 0.85s !important;
}

.custom-progress-spinner .p-progress-spinner-circle {
    stroke: blue !important; /* Substitua 'blue' pela cor desejada */
}


.pi-spin {
    animation-duration: 0.5s !important;
}
